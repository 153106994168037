import React, { useEffect, useState } from "react";
import { DeleteButton } from "../Button";

const uploadImage = (imageFile: File, callback: (img: string) => void) => {
  const { type: mimeType } = imageFile;

  const fileReader = new FileReader();
  fileReader.readAsDataURL(imageFile);
  fileReader.onload = (fileReaderEvent) => {
    // @ts-ignore
    const imageAsBase64 = fileReaderEvent.target.result;
    const image = document.createElement("img");
    if (!image || typeof imageAsBase64 !== "string") {
      return;
    }
    image.src = imageAsBase64;

    const imageMaxResizeWidth = 2000;

    const canvas = document.createElement("canvas");
    const actualImageWidth =
      image.width > imageMaxResizeWidth ? imageMaxResizeWidth : image.width;
    canvas.width = actualImageWidth;
    canvas.height = ~~(image.height * (actualImageWidth / image.width));
    const context = canvas.getContext("2d", { alpha: false });
    if (!context) {
      return;
    }
    context.drawImage(image, 0, 0, canvas.width, canvas.height);

    const resizedImageAsBase64 = canvas.toDataURL(mimeType, 0.7);
    callback(resizedImageAsBase64);
  };
};

export const UploadAndDisplayImage: React.FC<{
  setImg: (img: string | null) => void;
  index: number;
}> = ({ setImg, index }) => {
  const [selectedImage, setSelectedImage] = useState<null | File>(null);
  const [sizeError, setSizeError] = useState(false);

  useEffect(() => {
    if (selectedImage) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target?.result as string;
        image.onload = () => {
          uploadImage(selectedImage, (resized) => {
            setImg(resized);
            setSizeError(false);
          });
        };
      };
      reader.readAsDataURL(selectedImage);
    }
  }, [selectedImage]);

  return (
    <div>
      {selectedImage && (
        <div className="flex flex-col items-center gap-4">
          <img
            alt="img"
            width={"250px"}
            src={URL.createObjectURL(selectedImage)}
          />
          <DeleteButton onClick={() => setSelectedImage(null)}></DeleteButton>
        </div>
      )}

      <br />

      <input
        type="file"
        name={"img-" + index}
        onChange={(event) => {
          const file = event?.target?.files && event.target.files[0];
          if (file) {
            setSelectedImage(file); // Update the state with the selected file
          }
        }}
      />
    </div>
  );
};
