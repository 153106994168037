import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { LAST_WEEK_MSG, Message, User } from "./types";
import { getIsLastWeekFromNote, getIsNoteNew } from "./utils";

// Define the initial state using that type

type State = {
  messages?: Message[];
};
const initialState: State = {
  messages: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    init(state, action: PayloadAction<{ user: User }>) {
      state.messages = action.payload.user.messages;
    },
    updateMessage(
      state,
      action: PayloadAction<Message & { messageIndex: number }>
    ) {
      const { messageIndex, ...rest } = action.payload;
      const messageIndexCalc = action.payload.id
        ? state.messages?.findIndex(
            (message) => message.id === action.payload.id
          )
        : action.payload.messageIndex;
      if (
        state.messages &&
        messageIndexCalc !== undefined &&
        messageIndexCalc > -1
      ) {
        state.messages[messageIndexCalc] = rest;
      } else {
        state.messages?.push(rest);
      }
    },
    removeMessage(
      state,
      action: PayloadAction<{ messageId?: number; messageIndex: number }>
    ) {
      const messageIndex = action.payload.messageId
        ? state.messages?.findIndex(
            (message) => message.id === action.payload.messageId
          )
        : action.payload.messageIndex;
      if (state.messages && messageIndex !== undefined && messageIndex > -1) {
        state.messages.splice(messageIndex, 1);
      }
    },
  },
});

const { actions } = userSlice;

export const { init: initUser, updateMessage, removeMessage } = actions;

export const selectMessages = (state: RootState) =>
  state.user.messages?.filter((mex) => {
    if (!mex.text.user || !mex.text.user[0].msg) {
      return true;
    }
    return mex.text.user[0].msg !== LAST_WEEK_MSG;
  }) || [];

export const selectLastWeekMessages = (state: RootState) =>
  state.user.messages?.filter((mex) => {
    if (!mex.text.user || !mex.text.user[0].msg) {
      return false;
    }
    return mex.text.user[0].msg === LAST_WEEK_MSG;
  }) || [];

export const selectPTHasNewMessages = createSelector(
  selectMessages,
  (messages) => {
    return messages?.some((message) => {
      if (!message?.text?.user) {
        return false;
      }
      return getIsNoteNew(message.text.user[0]);
    });
  }
);

export const selectIsLastWeek = (state: RootState) =>
  state.user.messages?.some((mex) => {
    if (!mex.text.user || !mex.text.user[0]) {
      return false;
    }
    return getIsLastWeekFromNote(mex.text.user[0]);
  });
