import {
  FieldErrors,
  UseFormRegister,
  UseFormWatch,
  useForm,
} from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Message, User } from "../../../libs/user/types";

import { DeleteButton, SubmitButton } from "../../Button";
import {
  useDeleteUserMessagesMutation,
  useSetUserMessagesMutation,
} from "../../../libs/user/api";
import { useEffect } from "react";
import { selectGetUserResult } from "../../../libs/user/selectors";
import { useParams } from "react-router-dom";
import { updateMessage } from "../../../libs/user/slice";

type NoteFormProps = {
  onClose: () => void;
  messageIndex: number;
  message: Message;
};

type FormValues = {
  ptNote: string;
};
export const NoteForm: React.FC<NoteFormProps> = ({
  onClose,
  message,
  messageIndex,
}) => {
  const { id: userId } = useParams();
  const user = useAppSelector((state) => selectGetUserResult(state, userId));
  const userNote = message.text?.user?.[0];
  const ptNote = message.text?.pt?.[0];

  const ptNoteMsg = ptNote?.msg;
  const ptNoteCreatedAt = ptNote?.createdAt || "";

  const userNoteMsg = userNote?.msg;
  const userNoteCreatedAt = userNote?.createdAt || "";
  const userNoteReadAt = userNote?.readAt;

  const [setUserMessages, { data, error, isLoading }] =
    useSetUserMessagesMutation();
  const [
    deleteUserMessages,
    { error: deleteError, isLoading: deleteIsLoading },
  ] = useDeleteUserMessagesMutation();

  const dispatch = useAppDispatch();
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      ptNote: ptNoteMsg || "",
    },
  });

  const onSubmit = (formData: FormValues) => {
    if (!user || !user.id) {
      return;
    }
    if (ptNote?.msg === formData.ptNote) {
      onClose();
      return;
    }
    const payload = {
      idUser: user.id,
      id: message?.id,
      text: {
        ...message?.text,
        pt: [
          {
            msg: formData.ptNote || "",
            createdAt: Date.parse(new Date().toISOString()),
            readAt: null,
          },
        ],
      },
    };
    setUserMessages(payload)
      .unwrap()
      .then(() => {
        dispatch(updateMessage({ messageIndex, ...payload }));
        onClose();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const userNoteCreatedAtFormatted = new Date(
    userNoteCreatedAt
  ).toLocaleDateString(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
  const ptNoteCreatedAtFormatted = ptNoteCreatedAt
    ? new Date(ptNoteCreatedAt).toLocaleDateString(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      })
    : null;
  return (
    <form
      className="w-[80%] space-y-6 p-4 mb-4 flex flex-col items-center border-gray-200 border-2 rounded-sm"
      onSubmit={handleSubmit(onSubmit)}
    >
      {userNoteMsg && (
        <div className="w-full">
          <div className="flex flex-col items-center">
            <label
              htmlFor="message"
              className="block mb-2 text-xs font-bold text-gray-500 dark:text-white"
            >
              Nota di {user?.name} {user?.surname} del{" "}
              {userNoteCreatedAtFormatted}
            </label>
            <div>
              <p>
                {userNoteMsg}{" "}
                {userNoteReadAt && (
                  <span className="text-xs font-bold text-gray-500 dark:text-gray-400">
                    - Letto
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      )}
      <PTNoteBox
        register={register}
        errors={errors}
        watch={watch}
        ptNoteCreatedAtFormatted={ptNoteCreatedAtFormatted}
      />
      <div className="flex flex-row gap-2">
        <SubmitButton isPending={isLoading}>Invia Nota</SubmitButton>
        <DeleteButton
          isPending={deleteIsLoading}
          onClick={() => deleteUserMessages({ id: message.id })}
        />
        {(error || deleteError) && (
          <p> OPS! C'è stato un errore. Riprova più tardi!</p>
        )}
      </div>
    </form>
  );
};

type PTNoteBoxProps = {
  register: UseFormRegister<FormValues>;
  errors: FieldErrors<FormValues>;
  watch: UseFormWatch<FormValues>;
  ptNoteCreatedAtFormatted: string | null;
};

const PTNoteBox: React.FC<PTNoteBoxProps> = ({
  register,
  errors,
  watch,
  ptNoteCreatedAtFormatted,
}) => {
  const watchPtNote = watch("ptNote");

  return (
    <div className="w-full">
      <div className="flex flex-col items-center">
        {ptNoteCreatedAtFormatted && (
          <label
            htmlFor="message"
            className="block mb-2 text-xs font-bold text-gray-500 dark:text-white"
          >
            La tua nota del {ptNoteCreatedAtFormatted}
          </label>
        )}
        <textarea
          id="message"
          rows={4}
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          {...register("ptNote", {
            validate: (value: string) => {
              if (value.length > 300) {
                return "La nota deve essere di massimo 300 caratteri";
              }
              return true;
            },
          })}
        />
        <span className="text-sm text-gray-500 dark:text-gray-400">
          {watchPtNote?.length || 0}/300
        </span>
        {errors.ptNote && (
          <span className="text-red-500 text-sm">{errors.ptNote.message}</span>
        )}
      </div>
    </div>
  );
};
