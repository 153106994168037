import { Note } from "./types";

export const getIsNoteNew = (note?: Note) => {
  if (!note?.msg) {
    return false;
  }
  return !note.readAt;
};

export const getIsLastWeekFromNote = (note?: Note) => {
  if (!note?.msg) {
    return false;
  }
  return note.msg === "###LAST_WEEK###";
};
