"use client";

import { useForm } from "react-hook-form";
import { useCreateExerciseMutation } from "../../libs/exercises/api";
import { EXERCISE_TYPES } from "../../libs/exercises/types";
import { Modal } from "../Modal";
import { useEffect, useState } from "react";

export type BodyImgsModalProps = {
  openModal:
    | false
    | {
        imgs: string[];
        date: string;
      };
  setOpenModal: (value: any) => void;
};

export const BodyImgsModal: React.FC<BodyImgsModalProps> = ({
  openModal,
  setOpenModal,
}) => {
  const { imgs, date } = openModal || { imgs: [], date: "" };
  return (
    <Modal openModal={!!openModal} setOpenModal={setOpenModal} fullScreen>
      <div className="flex justify-between items-start pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
        {imgs.map((img, i) => (
          <BodyImg img={img} key={img + i} />
        ))}
      </div>
    </Modal>
  );
};

export const BodyImg: React.FC<{ img: string }> = ({ img }) => {
  // const { data: imgFile, isLoading, error } = useGetImgFromStorageQuery(img);
  // console.log(imgFile, error);
  const [imgFile, setImgFile] = useState<string>("");
  useEffect(() => {
    (async () => {
      const res = await fetch(
        `https://fitshapeapp.work/api/storage/download/${img}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "image/png",
            Authorization: "Bearer " + localStorage.getItem("userJwt"),
          },
        }
      );
      let blob = await res.blob();
      blob = blob.slice(0, blob.size, "image/png");
      const base64data = await new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
      setImgFile(base64data as string);
    })();
  }, []);
  if (!imgFile || imgFile === "") {
    return <div>-</div>;
  }

  return (
    <div className={`mx-2  max-w-[30%]  text-center`}>
      <a href={imgFile} download={img}>
        DOWNLOAD
      </a>
      <img src={imgFile} alt="body" className="py-2 mx-4" />
    </div>
  );
};
