import { Modal } from "../Modal";
import { useState } from "react";
import { UploadAndDisplayImage } from "./UploadAndDisplayImg";
import { SubmitButton } from "../Button";
import { useSetBodyMeasuresMutation } from "../../libs/history/api";
import { useParams } from "react-router-dom";
import { getToday } from "../../libs/utils/date";

export type AddBodyImgsModalProps = {
  openModal: false | true;
  setOpenModal: (value: any) => void;
};

export const AddBodyImgsModal: React.FC<AddBodyImgsModalProps> = ({
  openModal,
  setOpenModal,
}) => {
  const { id: userId } = useParams();
  const userIdParsed = userId ? parseInt(userId) : -1;

  const [imgs, setImgs] = useState<(string | null)[]>([null, null, null]);
  const [setBodyMeasures, { isLoading, error }] = useSetBodyMeasuresMutation();

  const onSubmit = () => {
    const newImgs = imgs.filter((img) => !!img) as string[];
    setBodyMeasures({
      bodyMeasures: {
        [getToday()]: {
          imgs: newImgs,
        },
      },
      userId: userIdParsed,
    })
      .unwrap()
      .then(() => {
        setOpenModal(false);
      })
      .catch((e) => {
        console.log("ERROR: ", e);
      });
  };

  return (
    <Modal openModal={!!openModal} setOpenModal={setOpenModal} fullScreen>
      <div className="text-lg font-medium text-gray-800 dark:text-gray-200">
        Aggiungi immagini
      </div>

      <div className="flex flex-wrap gap-4 justify-between items-end pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
        {imgs.map((img, index) => (
          <UploadAndDisplayImage
            key={index}
            setImg={(img: string | null) => {
              const newImgs = [...imgs];
              newImgs[index] = img;
              setImgs(newImgs);
            }}
            index={index}
          />
        ))}
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <SubmitButton isPending={isLoading}>
          <span>Salva</span>
        </SubmitButton>
        {error && (
          <div className="text-red-500 text-xs max-w-[200px]">
            Ops! Qualcosa è andato storto...
          </div>
        )}
      </form>
    </Modal>
  );
};
